import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';

const auth = getAuth();
onAuthStateChanged(auth, async (user) => {
  if (!user) window.location.replace('/login.html');
  const tokenResult = await user.getIdTokenResult();
  if (tokenResult.claims.admin) {
    console.log('user is admin');
    const adminElements = document.querySelectorAll('.admin');
    adminElements.forEach((el) => {
      el.style.display = 'block';
    });
  }
});

const logoutLink = document.getElementById('logout');
logoutLink.addEventListener('click', (e) => {
  e.preventDefault();
  signOut(auth);
});
