import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDA0rnP8vr8aJtwjBdGXhCMrzNPw_9YN0U',
  authDomain: 'donut-portal.firebaseapp.com',
  projectId: 'donut-portal',
  storageBucket: 'donut-portal.appspot.com',
  messagingSenderId: '834135552849',
  appId: '1:834135552849:web:f7c4f5ba53a2cfc5835970',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
