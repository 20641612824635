import { getFirestore, collection, getDocs } from 'firebase/firestore';
import moment from 'moment';
import app from './lib/firebase-app';
import './lib/nav';

const db = getFirestore(app);

const ordersColRef = collection(db, 'orders');

let orders = [];

function createOrderRow(doc) {
  const order = doc.data();
  const { products } = order;
  const orderSize = products.reduce((prevSize, product) => {
    return prevSize + product.quantity;
  }, 0);
  const row = document.createElement('tr');
  row.innerHTML = `
  <td>${order.firstName} ${order.lastName}</td>
  <td>${order.pickupDate} ${order.pickupTime}</td>
  <td>${orderSize}</td>`;
  const actionCell = document.createElement('td');
  const editButton = document.createElement('button');
  editButton.className = 'btn-floating btn-small waves-effect waves-light blue';
  editButton.innerHTML = '<i class="material-icons">edit</i>';
  editButton.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.assign(`/order-form.html?orderId=${doc.id}`);
  });
  row.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.assign(`/order.html?orderId=${doc.id}`);
  });
  actionCell.appendChild(editButton);
  row.appendChild(actionCell);
  return row;
}

function compareOrdersByTime(a, b) {
  const orderOne = a.data();
  const orderTwo = b.data();

  const timeA = moment(
    `${orderOne.pickupDate} ${orderOne.pickupTime}`,
    'MMM DD, YYYY hh:mm A'
  );
  const timeB = moment(
    `${orderTwo.pickupDate} ${orderTwo.pickupTime}`,
    'MMM DD, YYYY hh:mm A'
  );

  if (timeA.isBefore(timeB)) return -1;
  if (timeA.isAfter(timeB)) return 1;
  return 0;
}

async function getProducts() {
  try {
    // const getOrders = httpsCallable(funcs, 'getOrders');
    // const result = await getOrders();
    // const webflowOrders = result.data;
    const snapshot = await getDocs(ordersColRef);
    const fbOrders = snapshot.docs;
    orders = [...fbOrders];
    orders.sort(compareOrdersByTime);
    const tableBodyContent = new DocumentFragment();
    orders.forEach((order) => {
      const row = createOrderRow(order);
      tableBodyContent.appendChild(row);
    });
    document.querySelector('#orders tbody').replaceChildren(tableBodyContent);
  } catch (err) {
    console.error(err.stack);
  }
}

getProducts();

// functions setupOrderLists() {

// }
